import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

const CountUp = ({ fetchedValue, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const interval = fetchedValue > 0 ? duration / fetchedValue : 0;

    const counter = setInterval(() => {
      if (fetchedValue > 0) {
        start += 1;
        setCount(start);
        if (start === fetchedValue) clearInterval(counter);
      }
      else {
        setCount(0)
        clearInterval(counter);
      }
    }, interval);

    return () => clearInterval(counter);
  }, [fetchedValue, duration]);

  return (
    <Typography class="text-4xl font-extrabold text-blue-400 drop-shadow-[0_1.5px_1px_rgba(0,0,0,1)]" variant="h4" component="div" style={{ fontFamily: 'Inter' }}>
      {count}
    </Typography>
  );
};


export default CountUp;
