import React, { useEffect, useState } from "react";
import axios from 'axios'
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { BrowserRouter as Router,  Routes,  Route,  Link } from "react-router-dom";
import './App.css';
import TableComponent from "./components/Proposals/TableComponent";
import AnalyticsComponent from "./components/Proposals/AnalyticsComponent";
import BidsComponent from "./components/Bids/BidsComponent";
import PeopleComponent from "./components/PeopleComponent";
import Login from "./auth/Login";
import Register from "./auth/Register";
import PrivateRoute from "./auth/PrivateRoute"
import useAuthStore from './auth/authStore';
import TopBanner from "./components/Topbar";

function App() {
  // const [isCollapsed, setIsCollapsed] = useState(true);
  const setAuthState = useAuthStore((state) => state.setAuthState);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [isWindowSmall, setIsWindowSmall] = useState(window.innerWidth < 2000);
  const [isManuallyToggled, setIsManuallyToggled] = useState(false);
  const [hasBeenManuallyToggled, setHasBeenManuallyToggled] = useState(false);

  // Calculate the initial collapsed state based on window size
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 2000);

  // Function to handle window resize
  const handleResize = () => {
    const newSize = window.innerWidth < 1900;
    setIsWindowSmall(newSize);
    // Update isCollapsed based on new window size only if it hasn't been manually toggled
    if (!hasBeenManuallyToggled) {
      setIsCollapsed(newSize);
    }
  };

  // Function to handle manual toggle
  const handleManualToggle = () => {
    // Directly calculate the new collapsed state
    const newCollapsedState = !isCollapsed;
    setIsManuallyToggled(newCollapsedState);
    setIsCollapsed(newCollapsedState); // Update collapsed state
    setHasBeenManuallyToggled(true); // Indicate that the toggle has been manually used
  };

  // Effect to add and remove window resize listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
      axios.get('/api/auth/user', {withCredentials: true})
        .then(response => {
          // Set user as authenticated
          setAuthState(true, false, response.username, response.id);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            // Set user as not authenticated
            setAuthState(false, false, '', -1);
          } else {
            console.error(error);
          }
        });
    }, []);

  return(
      <Router>
          <TopBanner> </TopBanner>
         <div className="flex min-h-screen pt-16">
        {isAuthenticated && (
          <div style={{ width: isCollapsed ? '80px' : '250px',
                        flexShrink: 0, // Prevents the sidebar from shrinking
                        transition: 'width 0.3s ease',
                        backgroundColor: '#efefef',
                        height: 'auto',
                        minHeight: '100vh' }}>
          <Sidebar collapsed={isCollapsed} rootStyles={{
                [`.${sidebarClasses.container}`]: { 
                  backgroundColor: '#efefef',
                  color: 'black',
                },
              }}
            >
            <Menu iconShape="square"
              menuItemStyles={{
                button: ({ level, active }) => {
                  if (level === 0)
                    return {
                          backgroundColor: active ? '#90D5EC' : '#efefef',
                          color: active ? 'white' : 'black',
                          fontSize: '16px', // Adjust the font size as needed
                          "&:hover": {
                            backgroundColor: "#90D5EC",
                            color: "white",
                            borderRadius: "1px",
                          },
                          // Styles for the icon itself
                          "& .icon-class": { // Replace with actual class or selector for your icons
                            fontSize: '32px', // Large icon size
                            color: active ? 'white' : 'black',
                          },
                        };
                    },
                  }}
            >
                  <MenuItem icon={<img src="/logo.png" className="logo" alt="Logo" style={{ width: '100%', height: 'auto'}} />}
                      onClick={handleManualToggle}
                      style={{ marginTop: '20px', marginBottom: '40px' }}
                    >
                      <h5> DATABASE</h5>
                      
                  </MenuItem>
                  <MenuItem icon={<StorageOutlinedIcon />} component = {<Link to="/proposals" />}> JOC Proposals</MenuItem>
                  <MenuItem icon={<EqualizerOutlinedIcon />} component = {<Link to="/analytics" />}> NTP Analytics</MenuItem>
                  <MenuItem icon={<StorageOutlinedIcon />} component = {<Link to="/bids" />}> Bids</MenuItem>
                  <MenuItem icon={<PeopleOutlinedIcon />} component = {<Link to="/people" />}> People</MenuItem>
                  
            </Menu>
          </Sidebar>
          </div>
          )}
           <div style={{
            flexGrow: 1,
            marginLeft: isCollapsed ? '0' : 'sidebarWidth'}}>
        <Routes>
          <Route path="/" element ={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/proposals" element={<PrivateRoute element={<TableComponent/>} /> } />
          <Route path="/analytics" element={<PrivateRoute element={<AnalyticsComponent/>} /> } />
          <Route path="/bids" element={<PrivateRoute element={<BidsComponent/>} /> } />
          <Route path="/people" element={<PrivateRoute element={<PeopleComponent/>} /> } />
        </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
