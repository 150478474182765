import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpOutline from '@mui/icons-material/HelpOutline';
import useAuthStore from '../auth/authStore';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/system';
import { grey, red } from '@mui/material/colors';

import { Dropdown } from '@mui/base';
import { Popover, Menu, MenuItem, Avatar, Link, Typography } from '@mui/material';

const TopBanner = () => {
  let navigate = useNavigate();
  const { isAuthenticated, username, role } = useAuthStore();
  const logOut = useAuthStore(state => state.logOut);
  const [questionLoc, setQuestionLoc] = React.useState(null);
  const [profileLoc, setProfileLoc] = React.useState(null);
  const [notificationLoc, setNotificationLoc] = React.useState(null);
  const qOpen = Boolean(questionLoc);
  const nOpen = Boolean(notificationLoc);
  const pOpen = Boolean(profileLoc);

  const handleQClick = (event) => {
    setQuestionLoc(event.currentTarget);
  };

  const handleQClose = () => {
    setQuestionLoc(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationLoc(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationLoc(null);
  };

  const handleProfileClick = (event) => {
    setProfileLoc(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileLoc(null);
  };

  const handleLogout = () => {
    logOut(navigate);
    handleProfileClose();
  };

  const Listbox = styled('ul')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0 4px 6px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
    };
    z-index: 1;
    `,
  );

    return (
    <AppBar className="TopBar" position="fixed" style={{ background: '#2E3B55', width: '100%'}}>
        <Toolbar style ={{ justifyContent: 'space-between'}}>
            <img src='./logo.png' alt="Company Logo" style={{ flexGrow: 0, maxWidth: '150px' }} />
            {isAuthenticated && (
          <Box sx={{display: 'flex'}}>
                <IconButton color="inherit" onClick={handleQClick}>
                  <HelpOutline />
                </IconButton>
                <Popover
                  open={qOpen}
                  anchorEl={questionLoc}
                  onClose={handleQClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    horizontal: 'center'
                  }}
                >
                <Typography className="flex pt-2 pl-2 pr-2 justify-center">Have a problem?</Typography>
                <Link className="flex justify-center pb-2 pr-2 pl-2 pt-1" href="https://forms.gle/5JJJMy7EXMdoiGk76" underline="hover"> {'Submit a Ticket here'}</Link>
                </Popover>
                <Dropdown>
                  <IconButton color="inherit" onClick={handleNotificationClick}>
                      <Badge badgeContent={3} color="error">
                      <NotificationsIcon />
                      </Badge>
                  </IconButton>
                  <Menu anchorEl={notificationLoc}
                    open={nOpen}
                    onClose={handleNotificationClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    slots={{ listbox: Listbox }}>
                      <MenuItem className="bg-transparent" divider disableRipple disableTouchRipple> What's New?
                      </MenuItem>
                   <MenuItem className="mt-1"disableRipple disableTouchRipple> • Added a new bug report form!
                    </MenuItem>
                    <MenuItem disableRipple disableTouchRipple> • Updated Button color scheme. 
                    </MenuItem>
                    <MenuItem disableRipple disableTouchRipple> • Realigned UI components. 
                    </MenuItem>
                  </Menu>
                </Dropdown>
            <Dropdown>
              <IconButton edge="end" color="inherit" onClick={handleProfileClick}>
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={profileLoc}
                open={pOpen}
                onClose={handleProfileClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem disabled style={{ color: 'inherit', opacity: 0.8, padding: 13}}>
                  <Avatar style= {{marginRight:12}} /> {username}
                </MenuItem>
                <MenuItem disabled style={{ color: 'inherit', opacity: 0.8 }}>
                  <AssignmentIndIcon style= {{marginRight:8}}/> Role: {role.toUpperCase()}
                  </MenuItem>
                <MenuItem disabled style={{ height: 5 }} >
                  <hr style={{ width: '100%', margin: 0 }} />
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <LogoutIcon style= {{marginRight:8}}/> 
                      Logout
                </MenuItem>
              </Menu>
            </Dropdown>
          </Box>
        )}
      </Toolbar>
    </AppBar>
    );
};

export default TopBanner;