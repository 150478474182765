import React from 'react';
import { Grid, Stack, Typography, Avatar } from '@mui/material';
import MainCard from '../MainCard';
import Box from '@mui/material/Box';

function numberToColorHSL(number) {
  // Ensure the number is within the range 0 to 199
  const frequency = 0.1;
  const phase = number * Math.PI * 2;

  // Calculating sine and cosine values for the given ID
  const sine = Math.sin(frequency * phase);
  const cosine = Math.cos(frequency * phase);

  // Map the sine and cosine values to the 0-360 range for hue
  const hue = Math.floor(((sine + 1) / 2) * 360);
  const saturation = Math.floor(((cosine + 1) / 2) * 65);

  // Keeping lightness in a moderate range to ensure color visibility
  const lightness = 50;

  // Return the HSL color
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
const EstimatorProjects = ({ status, projectCount, estimatorCount, statusMapping }) => (
  <MainCard contentSX={{ p: 2.25 }} sx={{
  bgcolor: 'rgba(250, 250, 250, 0.5)', // slightly off-white background
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // drop shadow
  borderRadius: '4px' 
  }}>
  {/* Card content */}
    <Stack spacing={1.5}>
      <Typography className="text-black" variant="h6">
        {statusMapping[status]}: {projectCount}
      </Typography>
      <Grid container alignItems="center">
        <Grid item>
          {/* <Typography variant="h4" color="inherit">
            {projectCount}
          </Typography> */}
        </Grid>
          <Grid item>            
          </Grid>
      </Grid>
    </Stack>
    {/* <Typography variant="caption" color="textSecondary">
        Proposals per Estimator:{' '}
        <Typography component="span" variant="caption" sx={{ color: `${'primary' || 'test' }.main` }}>
        </Typography>{' '}
      </Typography> */}
      {projectCount > 0
          ? (
        <Box sx={{ pt: 1.25 }}>
        {
          Object.entries(estimatorCount || {} ).map(([key,value]) => {
            const initials = key.split(' ').map((n)=>n[0]).join('').toUpperCase();
          return (
            <Grid container alignItems="center" key={key}>
            <Avatar  sx={{ bgcolor: numberToColorHSL(value.estimator_id)}}>
              {initials}
            </Avatar>
            <Grid padding={1.2}>
              <Typography variant="h8" color="textSecondary"> {key}: {value.num_projects} </Typography>
            </Grid>
          </Grid>
          );
          })
        }
        </Box>
            ) :  null // Empty state, including when projectCount is 0
      }
  </MainCard>
);


export default EstimatorProjects